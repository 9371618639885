<template>
<!-- 街道的 -->
    <div>
        <div class="goBack">
            <div @click="getBack('street')" style="cursor: pointer;">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span  @click="countiesopen">{{pitchon}}</span>
            <img src="../../assets/images/pull-down.png" alt="" style="cursor: pointer;" @click="countiesopen">
            <ul class="counties" v-show="counties">
                <li class="countiesli" v-for="(item,index) in select" :key="index" @click="countiesclick" :title=item>{{item}}</li>
            </ul>
        </div>
        <div>
            <jishantwo v-if="jishantwo"></jishantwo>
        </div>
    </div>
</template>

<script>
import { show } from '../../assets/js/animate.js'
import bus from '/src/assets/js/bus.js'
export default {
    components: {
        jishantwo: () => import('./jishantwo.vue'),
    },
    data () {
        return {
            jishantwo:true,
            // pitchon: "稽山街道",
            pitchon: window.clickstreet,
            counties:false,
            // select:[ "东浦镇","斗门镇","灵芝镇","马山镇","孙瑞镇","陶堰镇","东湖镇","北海街道","府山街道","迪荡街道","塔山街道","稽山街道","城南街道"]  
            select:window.completestreet,
        }
    },
    methods: {
        // 下拉箭头选择显示隐藏
        countiesopen(){
            if(this.counties === false){
                this.counties = true;
            }else{
                this.counties = false;  
            }
        },
        countiesclick(e){
            let _this = this;
            var stree = e.target.innerText
            // if(stree === "稽山街道"){
                this.pitchon = e.target.innerText
                this.counties = false;
                window.clickstreet = this.pitchon;
                // console.log(window.clickstreet)
                bus.$emit('now_street',this.pitchon);
            // }
        },
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
            $('#amap').show();
        },
    },
}
</script>

<style scoped>
.counties{
    display: inline-block;
    position: absolute;
    top:0.4rem;
    left:0.6rem;
    color:#F1F1F1;
    font-size: 0.19rem;
    text-align: center;
    background:#000000B3;
    padding:0.06rem 0rem;
    box-sizing: border-box;
    max-height:4.1rem;
    overflow-x: hidden;
    overflow-y: scroll;
}
.counties::-webkit-scrollbar {
        display: none;
    }
.countiesli{
    width:1.60rem;
    line-height: 0.40rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding:0rem 0.1rem;
    box-sizing: border-box;
}
.countiesli:hover{
    color:#20D7FE;
    cursor: pointer;
}
.goBack>img{
    width:0.12rem;
    height:0.1rem;
    margin:0.12rem;
    cursor: pointer;
}
</style>
